<template>
  <div
                       class="d-flex flex-wrap"
                        :style="
                         getMarginCss(pro.style, isDesktopView) +
                          'justify-content:' +
                          pro.style.position +
                          ';'
                        "
                        
                      >
                        
                        <div
                        v-if="pro.isInstagramVisible"
                         class="mx-1"
                          :style="getSocialLinkBtnStyles(pro.style, isDesktopView)"
                          @click="openSocialLink(pro.instagramValue)"
                        >
                        
    <svg class="SocialIconSvg" width="18" height="18" viewBox="0 0 18 18" style="width:100%;height:100%;overflow:hidden;" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.00008 2.16805C11.2251 2.16805 11.489 2.17638 12.3674 2.21666C13.2758 2.25832 14.2112 2.46527 14.873 3.12707C15.5411 3.79513 15.7417 4.72152 15.7834 5.63263C15.8237 6.5111 15.832 6.77499 15.832 8.99999C15.832 11.225 15.8237 11.4889 15.7834 12.3674C15.7424 13.2708 15.5306 14.2153 14.873 14.8729C14.2049 15.541 13.2792 15.7417 12.3674 15.7833C11.489 15.8236 11.2251 15.8319 9.00008 15.8319C6.77508 15.8319 6.51119 15.8236 5.63272 15.7833C4.73619 15.7424 3.77925 15.5257 3.12716 14.8729C2.46258 14.2083 2.25841 13.2729 2.21675 12.3674C2.17647 11.4889 2.16814 11.225 2.16814 8.99999C2.16814 6.77499 2.17647 6.5111 2.21675 5.63263C2.25772 4.73263 2.47161 3.78263 3.12716 3.12707C3.79383 2.46041 4.72369 2.25832 5.63272 2.21666C6.51119 2.17638 6.77508 2.16805 9.00008 2.16805ZM9.00008 0.666656C6.73689 0.666656 6.45286 0.676379 5.56397 0.716656C4.27578 0.775684 2.99661 1.13402 2.06536 2.06527C1.13064 2.99999 0.775776 4.27638 0.716748 5.56388C0.67647 6.45277 0.666748 6.7368 0.666748 8.99999C0.666748 11.2632 0.67647 11.5472 0.716748 12.4361C0.775776 13.7229 1.1355 15.0055 2.06536 15.9347C2.99939 16.8687 4.27786 17.2243 5.56397 17.2833C6.45286 17.3236 6.73689 17.3333 9.00008 17.3333C11.2633 17.3333 11.5473 17.3236 12.4362 17.2833C13.7237 17.2243 15.0042 16.8653 15.9348 15.9347C16.8702 14.9993 17.2244 13.7236 17.2834 12.4361C17.3237 11.5472 17.3334 11.2632 17.3334 8.99999C17.3334 6.7368 17.3237 6.45277 17.2834 5.56388C17.2244 4.27568 16.8654 2.99582 15.9348 2.06527C15.0022 1.13263 13.7202 0.77499 12.4362 0.716656C11.5473 0.676379 11.2633 0.666656 9.00008 0.666656Z" fill="#18191C"/>
<path d="M9.00016 4.83331C6.6991 4.83331 4.8335 6.69891 4.8335 8.99998C4.8335 11.301 6.6991 13.1666 9.00016 13.1666C11.3012 13.1666 13.1668 11.301 13.1668 8.99998C13.1668 6.69891 11.3012 4.83331 9.00016 4.83331ZM9.00016 11.7047C7.50646 11.7047 6.29541 10.4937 6.29541 8.99998C6.29541 7.50628 7.50646 6.29523 9.00016 6.29523C10.4939 6.29523 11.7049 7.50628 11.7049 8.99998C11.7049 10.4937 10.4939 11.7047 9.00016 11.7047Z" fill="#18191C"/>
<path d="M13.1668 5.66667C13.6271 5.66667 14.0002 5.29357 14.0002 4.83333C14.0002 4.3731 13.6271 4 13.1668 4C12.7066 4 12.3335 4.3731 12.3335 4.83333C12.3335 5.29357 12.7066 5.66667 13.1668 5.66667Z" fill="#18191C"/>
</svg>

                        </div>
                        <div
                        v-if="pro.isFacebookVisible"
                         class="mx-1"
                          :style="getSocialLinkBtnStyles(pro.style, isDesktopView)"
                          @click="openSocialLink(pro.facebookValue)"
                        >
                        
  <svg class="SocialIconSvg" width="18" height="18" viewBox="0 0 18 18" style="width:100%;height:100%;overflow:hidden;" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.00008 0.666672C4.40511 0.666672 0.666748 4.40504 0.666748 9.00001C0.666748 13.595 4.40511 17.3333 9.00008 17.3333C13.5951 17.3333 17.3334 13.595 17.3334 9.00001C17.3334 4.40504 13.5951 0.666672 9.00008 0.666672ZM9.00008 1.91667C12.9195 1.91667 16.0834 5.08059 16.0834 9.00001C16.0834 12.492 13.5697 15.3777 10.2501 15.967V11.0833H11.7605C11.9705 11.0833 12.1481 10.9263 12.1739 10.7179L12.3302 9.46794C12.3452 9.34961 12.3076 9.23079 12.2284 9.14079C12.1497 9.05121 12.0363 9.00001 11.9167 9.00001H10.2501V7.54167C10.2501 7.08209 10.6238 6.70834 11.0834 6.70834H11.9167C12.1467 6.70834 12.3334 6.52209 12.3334 6.29167V4.88542C12.3334 4.66959 12.1688 4.48955 11.9542 4.47038C11.9296 4.4683 11.3417 4.41667 10.5976 4.41667C8.76132 4.41667 7.75008 5.50675 7.75008 7.48633V9.00001H6.08342C5.85342 9.00001 5.66675 9.18626 5.66675 9.41667V10.6667C5.66675 10.8971 5.85342 11.0833 6.08342 11.0833H7.75008V15.967C4.43042 15.3777 1.91675 12.492 1.91675 9.00001C1.91675 5.08059 5.08066 1.91667 9.00008 1.91667Z" fill="#18191C"/>
</svg>
                        </div>
                        <div
                        v-if="pro.isLinkedInVisible"
                         class="mx-1"
                          :style="getSocialLinkBtnStyles(pro.style, isDesktopView)"
                          @click="openSocialLink(pro.linkedInValue)"
                        >
                        
    <svg class="SocialIconSvg" width="14" height="14" viewBox="0 0 14 14" style="width:100%;height:100%;overflow:hidden;" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.6875 0.125C0.833008 0.125 0.125 0.833008 0.125 1.6875V12.3125C0.125 13.167 0.833008 13.875 1.6875 13.875H12.3125C13.167 13.875 13.875 13.167 13.875 12.3125V1.6875C13.875 0.833008 13.167 0.125 12.3125 0.125H1.6875ZM1.6875 1.375H12.3125C12.4907 1.375 12.625 1.50928 12.625 1.6875V12.3125C12.625 12.4907 12.4907 12.625 12.3125 12.625H1.6875C1.50928 12.625 1.375 12.4907 1.375 12.3125V1.6875C1.375 1.50928 1.50928 1.375 1.6875 1.375ZM3.52344 2.44922C2.93018 2.44922 2.44922 2.93018 2.44922 3.52344C2.44922 4.1167 2.93018 4.59766 3.52344 4.59766C4.1167 4.59766 4.59766 4.1167 4.59766 3.52344C4.59766 2.93018 4.1167 2.44922 3.52344 2.44922ZM9.16797 5.30078C8.27197 5.30078 7.67627 5.7915 7.42969 6.25781H7.39062V5.4375H5.63281V11.375H7.46875V8.44531C7.46875 7.67139 7.62256 6.92188 8.58203 6.92188C9.52686 6.92188 9.53906 7.7959 9.53906 8.48438V11.375H11.375V8.11328C11.375 6.5166 11.0356 5.30078 9.16797 5.30078ZM2.625 5.4375V11.375H4.48047V5.4375H2.625Z" fill="#18191C"/>
</svg>
                        </div>
                        <div
                        v-if="pro.isTwitterVisible"
                         class="mx-1"
                          :style="getSocialLinkBtnStyles(pro.style, isDesktopView)"
                          @click="openSocialLink(pro.twitterValue)"
                        >

 
<!-- <svg class="SocialIconSvg"  width="48" height="40" viewBox="0 0 48 40" style="width:100%;height:100%;overflow:hidden;" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.095 39.5014C33.2083 39.5014 43.1155 24.4946 43.1155 11.4809C43.1155 11.0546 43.1155 10.6303 43.0867 10.2079C45.0141 8.81384 46.6778 7.0877 48 5.11033C46.2028 5.90713 44.2961 6.42944 42.3437 6.65977C44.3996 5.42915 45.9383 3.49333 46.6733 1.21273C44.7402 2.35994 42.6253 3.16838 40.4198 3.60313C38.935 2.02428 36.9712 0.97881 34.8324 0.628496C32.6935 0.278183 30.4988 0.642557 28.5879 1.66523C26.677 2.68791 25.1564 4.31187 24.2615 6.28582C23.3665 8.25977 23.1471 10.4737 23.6371 12.5849C19.7218 12.3885 15.8915 11.371 12.3949 9.59834C8.89831 7.82572 5.81353 5.33765 3.3408 2.29561C2.08146 4.4636 1.69574 7.03006 2.2622 9.47245C2.82865 11.9148 4.30468 14.0495 6.38976 15.4418C4.82246 15.3959 3.2893 14.9731 1.92 14.2092V14.334C1.92062 16.6077 2.7077 18.8112 4.14774 20.5707C5.58778 22.3303 7.59212 23.5375 9.8208 23.9878C8.37096 24.3832 6.84975 24.441 5.37408 24.1567C6.00363 26.1134 7.22886 27.8244 8.87848 29.0506C10.5281 30.2768 12.5197 30.9569 14.5747 30.9958C12.5329 32.6007 10.1946 33.7873 7.69375 34.4878C5.19287 35.1882 2.57843 35.3886 0 35.0777C4.50367 37.9677 9.74385 39.5007 15.095 39.4937" fill="#18191C"/>
</svg> -->

<svg class="SocialIconSvg" width="14" height="14" style="width:100%;height:100%;overflow:hidden;" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_187_4504)">
<path d="M8.18312 5.8192L13.3019 0H12.0889L7.64428 5.05273L4.09438 0H0L5.36814 7.64062L0 13.7429H1.21305L5.90667 8.40709L9.65562 13.7429H13.75L8.18282 5.8192H8.18312ZM6.52168 7.70793L5.97778 6.9471L1.65013 0.893071H3.5133L7.00576 5.77887L7.54967 6.5397L12.0895 12.8905H10.2263L6.52168 7.70823V7.70793Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_187_4504">
<rect width="13.75" height="13.75" fill="white"/>
</clipPath>
</defs>
</svg>
 
                        </div>
                        <div
                        v-if="pro.isYoutubeVisible"
                         class="mx-1"
                          :style="getSocialLinkBtnStyles(pro.style, isDesktopView)"
                          @click="openSocialLink(pro.youtubeValue)"
                        >
                        
  <svg class="SocialIconSvg" width="18" height="12" viewBox="0 0 18 12" style="width:100%;height:100%;overflow:hidden;" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.00508 11.8433H8.98842C8.93675 11.8433 3.76759 11.8333 2.46342 11.4792C1.74627 11.286 1.18621 10.7256 0.993418 10.0083C0.7589 8.6853 0.645652 7.34362 0.655085 6C0.649026 4.65418 0.765338 3.31059 1.00259 1.98583C1.20067 1.26793 1.75828 0.704957 2.47425 0.499999C3.74258 0.166666 8.76925 0.166666 8.98259 0.166666H9.00009C9.05259 0.166666 14.2351 0.176666 15.5259 0.530833C16.2416 0.725007 16.8005 1.28425 16.9943 2C17.2362 3.32795 17.3498 4.67611 17.3334 6.02583C17.3393 7.37003 17.2227 8.71196 16.9851 10.035C16.7898 10.7514 16.2294 11.3105 15.5126 11.5042C14.2459 11.84 9.21842 11.8433 9.00508 11.8433ZM7.33842 3.50417L7.33425 8.50417L11.6776 6.00417L7.33842 3.50417Z" fill="#18191C"/>
</svg>

 
                        </div>
                        <div
                        v-if="pro.isTikTokVisible"
                         class="mx-1"
                          :style="getSocialLinkBtnStyles(pro.style, isDesktopView)"
                          @click="openSocialLink(pro.tikTokValue)"
                        >
                        
                        
                         

<svg class="SocialIconSvg" width="41" height="48" viewBox="0 0 41 48" style="width:100%;height:100%;overflow:hidden;" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.1451 0H22.0556V32.6956C22.0556 36.5913 18.9444 39.7913 15.0725 39.7913C11.2007 39.7913 8.08938 36.5913 8.08938 32.6956C8.08938 28.8696 11.1315 25.7391 14.8651 25.6V17.3913C6.63744 17.5304 0 24.2783 0 32.6956C0 41.1827 6.77571 48 15.1417 48C23.5075 48 30.2833 41.1131 30.2833 32.6956V15.9304C33.3255 18.1565 37.059 19.4783 41 19.5479V11.3391C34.9157 11.1304 30.1451 6.12173 30.1451 0Z" fill="#18191C"/>
</svg>


 
                        </div>
                        <div
                        v-if="pro.isPinterestVisible"
                         class="mx-1"
                          :style="getSocialLinkBtnStyles(pro.style, isDesktopView)"
                          @click="openSocialLink(pro.pinterestValue)"
                        >
                        
                        
                         
<svg class="SocialIconSvg" width="48" height="48" viewBox="0 0 48 48" style="width:100%;height:100%;overflow:hidden;" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 0C10.7438 0 0 10.7438 0 24C0 34.1719 6.32812 42.8531 15.2531 46.35C15.0469 44.4469 14.85 41.5406 15.3375 39.4688C15.7781 37.5938 18.15 27.5437 18.15 27.5437C18.15 27.5437 17.4281 26.1094 17.4281 23.9813C17.4281 20.6438 19.3594 18.15 21.7687 18.15C23.8125 18.15 24.8063 19.6875 24.8063 21.5344C24.8063 23.5969 23.4937 26.6719 22.8187 29.5219C22.2562 31.9125 24.0187 33.8625 26.3719 33.8625C30.6375 33.8625 33.9187 29.3625 33.9187 22.875C33.9187 17.1281 29.7937 13.1063 23.8969 13.1063C17.0719 13.1063 13.0594 18.225 13.0594 23.5219C13.0594 25.5844 13.8562 27.7969 14.85 28.9969C15.0469 29.2312 15.075 29.4469 15.0187 29.6813C14.8406 30.4406 14.4281 32.0719 14.3531 32.4C14.25 32.8406 14.0063 32.9344 13.5469 32.7188C10.5469 31.3219 8.67188 26.9438 8.67188 23.4188C8.67188 15.8438 14.175 8.89688 24.525 8.89688C32.85 8.89688 39.3187 14.8313 39.3187 22.7625C39.3187 31.0312 34.1063 37.6875 26.8688 37.6875C24.4406 37.6875 22.1531 36.4219 21.3656 34.9313C21.3656 34.9313 20.1656 39.5156 19.875 40.6406C19.3312 42.7219 17.8687 45.3375 16.8937 46.9313C19.1437 47.625 21.525 48 24 48C37.2562 48 48 37.2562 48 24C48 10.7438 37.2562 0 24 0Z" fill="#18191C"/>
</svg>


 
                        </div>
                      </div>
</template>

<script>

import cssFunctionMixin from "../customize/mixin/cssFunction";
export default {
 mixins: [cssFunctionMixin],
 props:['pro','isDesktopView'],
methods:{
    openSocialLink(link){
        if(link){
          let pattern = /^((http|https|ftp):\/\/)/;
          let URL = link
        if (!pattern.test(URL)) {
          URL = "https://" + URL;
        }
        window.open(URL,"_blank");
        }
    }
}
}
</script>

<style scoped>
.SocialIconSvg path{
   fill: var(--socialIconColor) !important;
  
}
</style>